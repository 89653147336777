var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v(" Details Solution Product " + _vm._s(_vm.Model.Description)),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _vm.hasSolutionProduct
            ? _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Code", readonly: "" },
                            model: {
                              value: _vm.Model.Code,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Code", $$v)
                              },
                              expression: "Model.Code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Typology", readonly: "" },
                            model: {
                              value: _vm.Model.Typology,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Typology", $$v)
                              },
                              expression: "Model.Typology",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("pga-text-field", {
                            attrs: { label: "Description", readonly: "" },
                            model: {
                              value: _vm.Model.Description,
                              callback: function ($$v) {
                                _vm.$set(_vm.Model, "Description", $$v)
                              },
                              expression: "Model.Description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "2" } },
                        [
                          _c("pga-entity-status", {
                            attrs: { title: "Status" },
                            model: {
                              value: _vm.status,
                              callback: function ($$v) {
                                _vm.status = $$v
                              },
                              expression: "status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }