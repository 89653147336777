var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditSolutionProduct" } },
            [
              _vm.isEditable
                ? _c("pga-edit-button", { on: { click: _vm.Edit } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "pga-authorized-only-one-content",
            {
              attrs: {
                permissions: [
                  "DeleteSolutionProduct",
                  "RequestDeletionSolutionProduct",
                ],
              },
              scopedSlots: _vm._u([
                {
                  key: "DeleteSolutionProduct",
                  fn: function () {
                    return [
                      _c("pga-delete-button", {
                        on: { click: _vm.ShowDialogDelete },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "RequestDeletionSolutionProduct",
                  fn: function () {
                    return [
                      _vm.isRequestableDeletion
                        ? _c("pga-delete-button", {
                            attrs: { text: "Request Deletion" },
                            on: { click: _vm.ShowDialogRequestDelete },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_c("pga-delete-button", { on: { click: _vm.ShowDialogDelete } })],
            1
          ),
          _vm.isApprovableDeletion
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "ApproveDeletionSolutionProduct" } },
                [
                  _c("pga-approve-button", {
                    attrs: { text: "Approve Deletion" },
                    on: { click: _vm.approveDeletion },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isApprovable
            ? _c(
                "pga-authorized-content",
                { attrs: { permission: "ApproveSolutionProduct" } },
                [
                  _c("pga-approve-button", { on: { click: _vm.approve } }),
                  _c("pga-request-changes-button", {
                    on: { click: _vm.requestChanges },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.solutionProductDetails } },
        [
          _c("pga-solution-products-details", {
            model: {
              value: _vm.solutionProductDetails,
              callback: function ($$v) {
                _vm.solutionProductDetails = $$v
              },
              expression: "solutionProductDetails",
            },
          }),
          _c("pga-dialog", {
            attrs: {
              title: _vm.title,
              "icon-title": "mdi-delete-alert",
              message: _vm.message,
              color: "orange",
              "icon-button": "mdi-checkbox-marked-circle-outline",
            },
            on: { agree: _vm.deleteSolutionProduct, reject: _vm.HideDialog },
            model: {
              value: _vm.showDialog,
              callback: function ($$v) {
                _vm.showDialog = $$v
              },
              expression: "showDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }