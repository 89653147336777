var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditSolutionProduct" } },
            [
              _c("pga-save-button", {
                on: { click: _vm.CreateSolutionProduct },
              }),
            ],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c("pga-solution-products-create", {
        model: {
          value: _vm.solutionProductCreate,
          callback: function ($$v) {
            _vm.solutionProductCreate = $$v
          },
          expression: "solutionProductCreate",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }